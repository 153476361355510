export default {
    state: () => ({ 
      registros: {},
      filtros: {
        presupuestos: 0,
        empresas: 0,
        facturas_geo: 0,
        obras: 0
      },  
      largos: {
        presupuestos: null,
        empresas: null,
        facturas_geo: null,
        obras: null,
        contactos: null
      }
    }),
    mutations: { },
    actions: { },
    getters: { }
  }