import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/presupuestos',
    name: 'Presupuestos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "presupuestos" */ '../views/Presupuestos.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/empresas',
    name: 'Empresas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "empresas" */ '../views/Empresas.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/facturas',
    name: 'Facturas',
    component: () => import(/* webpackChunkName: "facturas" */ '../views/Facturas.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/obras',
    name: 'Obras',
    component: () => import(/* webpackChunkName: "obras" */ '../views/Obras.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/contactos',
    name: 'Contactos',
    component: () => import(/* webpackChunkName: "contactos" */ '../views/Contactos.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/detallepresupuestos/:id',
    name: 'DetallePresupuesto',
    component: () => import(/* webpackChunkName: "detallepresupuestos" */ '../views/DetallePresupuesto.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/detalleempresas/:id',
    name: 'DetalleEmpresa',
    component: () => import(/* webpackChunkName: "detalleempresas" */ '../views/DetalleEmpresa.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/detallefacturas_geo/:id',
    name: 'DetalleFacturaGeo',
    component: () => import(/* webpackChunkName: "detallefacturas_geo" */ '../views/DetalleFacturaGeo.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/detalleobras/:id',
    name: 'DetalleObra',
    component: () => import(/* webpackChunkName: "detalleobras" */ '../views/DetalleObra.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/detallecontactos/:id',
    name: 'DetalleContacto',
    component: () => import(/* webpackChunkName: "detallecontactos" */ '../views/DetalleContacto.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    },
  },
  {
    path: '/preferencias',
    name: 'Preferencias',
    component: () => import(/* webpackChunkName: "preferencias" */ '../views/Preferencias.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.listas.idioma.length<=0){
          await store.dispatch('cargarlistas')
        }
        next()
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: () => import(/* webpackChunkName: "preferencias" */ '../views/Usuarios.vue'),
    beforeEnter: async (to,from,next) => {
      store.state.cache.auth=false
      await store.dispatch('auth')
      if(store.state.cache.auth===true){
        if(store.state.cache.user<5){
          next()
        }else{
          next({name:'Home'})
        }
      }else{
        localStorage.removeItem('token')
        next({name:'Login'})
      }
    }
  },
  {
    path: "/:catchAll(.*)",
    name: 'any',
    redirect: {name:'Home'}
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
