import { createStore } from 'vuex'
import Tablas from '@/stor/tablas.js'
import Listas from '@/stor/listas.js'
import Detalles from '@/stor/detalles.js'
import Preferencias from '@/stor/preferencias.js'
import Cache from '@/stor/cache.js'

export default createStore({
  modules: {
    tablas: Tablas,
    listas: Listas,
    detalles: Detalles,
    preferencias: Preferencias,
    cache: Cache
  }
})