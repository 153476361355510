export default {
    state: () => ({ 
      presupuestos: {
        registros: 100,
        longitud: null,
        campos: null,
        filtros: {},
        datos: [],
        orden: "NUM DESC"
      },
      obras: null,
      empresas: {
        registros: 100,
        longitud: null,
        campos: null,
        filtros: {},
        datos: [],
        orden: "EMPRESA ASC"
      },
      facturas_geo: null,
      facturas_son: null,
      contactos: null
    }),
    mutations: {
      cpedir(state,[tabla,data,largo,offset]){
        state[tabla].longitud = parseInt(largo)
        if(offset){
          let a = state[tabla].datos.concat(data)
          state[tabla].datos = a
        }else{
          state[tabla].datos = data
        }
      },
      cfiltrar(state,[e,tabla]){
        state[tabla].filtros = e
      },
      cget_campos(state,[tabla,data]){
        let suma = 0
        state[tabla].campos = data.map(function (object){
          if(object.FIJADO && parseInt(object.FIJADO)!==0){
            let devuelta = {
              nombre: object.NOMBRE,
              titulo: object.TITULO,
              tipo: object.TIPO,
              ancho: parseInt(object.ANCHO),
              fijado: suma,
              link: object.LINK
            }
            suma += parseInt(object.ANCHO) + 7
            return devuelta
          }else{
            return {
              nombre: object.NOMBRE,
              titulo: object.TITULO,
              tipo: object.TIPO,
              ancho: parseInt(object.ANCHO),
              fijado: 'unset',
              link: object.LINK
            }
          }
        })
      },
      cordenar(state,[e,tabla]){
        state[tabla].orden = e
      }
    },
    actions: {
      async api({state},post){
        let query='',limite='',orden='',tabla='',filtros='',campos = ''
        const archivo = post.archivo
        if(post.tipo && post.tipo === 'select'){
          if(post.limite){
            if(post.offset){
              limite = "LIMIT "+post.limite+" OFFSET "+post.offset
            }else{
              limite = "LIMIT "+post.limite
            }
          }
          if(post.orden){
            orden = "ORDER BY "+post.orden+" "
          }
          if(post.filtros && Object.keys(post.filtros).length>0){
            let array = []
            for (const key in post.filtros) {
              if (post.filtros.hasOwnProperty(key)) {
                array.push(key+post.filtros[key])
              }
            }
            filtros = 'WHERE '+array.join(' AND ')+' '
          }
          tabla = post.tabla+' '
          campos = post.campos.join(',')
          query = "SELECT "+campos+" FROM "+tabla+filtros+orden+limite+';'
        }else if(post.tipo && post.tipo==='update'){
          if(post.filtros && Object.keys(post.filtros).length>0){
            let array = []
            for (const key in post.filtros) {
              if (post.filtros.hasOwnProperty(key)) {
                array.push(key+post.filtros[key])
              }
            }
            filtros = 'WHERE '+array.join(' AND ')
          }
          if(post.campos && Object.keys(post.campos).length>0){
            let array = []
            for (const key in post.campos) {
              if (post.campos.hasOwnProperty(key)) {
                array.push(key+post.campos[key])
              }
            }
            campos = array.join(',')+' '
          }
          tabla = post.tabla+' '
          query = 'UPDATE '+tabla+'SET '+campos+filtros
        }
        const datos = new FormData
        if(post.respond){
          datos.append('respond',post.respond)
        }else{
          datos.append('respond',false)
        }
        datos.append('query',query)
        const data = await fetch('https://www.centrocatalan.synology.me/php2/'+archivo+'.php',{
          method: 'POST',
          body: datos
        })
        const res = await data.json()
        return res
      }, // Funciona Ok
      async pedir({state,commit,dispatch},[offset,limit,tabla]){
        let datos = await dispatch('api',{
          archivo: 'madre',
          tipo: 'select',
          campos: state[tabla].campos.map(function (object){
            let array = []
            if(object.tipo==='option' || object.tipo==='option-long' || object.tipo === 'date'){
              array.push('Z'+object.nombre)
            }
            array.push(object.nombre)
            return array
          }),
          tabla: tabla.toUpperCase(),
          filtros: state[tabla].filtros,
          limite: limit || state[tabla].registros,
          orden: state[tabla].orden,
          respond: 'true',
          offset: offset
        }) // Peticion a BBDD de los datos a mostrar en la tabla
        let a = state[tabla]
        let z = datos.map(function (object){
          let registro = object
          let devolver = {}
          a.campos.forEach(objeto => {
            if(objeto.tipo === 'option' || objeto.tipo === 'option-long' || objeto.tipo === 'date'){
              devolver[objeto.nombre] = registro['Z'+objeto.nombre]
            }else if(objeto.tipo==='boolean'){
              if(registro[objeto.nombre]!==null){
                devolver[objeto.nombre] = Boolean(parseInt(registro[objeto.nombre]))
              }else{
                devolver[objeto.nombre] = registro[objeto.nombre]
              }
            }else if(objeto.tipo==='money' && registro[objeto.nombre]!==null){
              devolver[objeto.nombre] = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(registro[objeto.nombre])
            }else{
              devolver[objeto.nombre] = registro[objeto.nombre]
            }
          })
          return devolver
        }) // Adecuacion de los datos al formato requerido
        let largo = await dispatch('api',{
          archivo: 'madre',
          tipo: 'select',
          campos: ['COUNT(ID) AS largo'],
          tabla: tabla.toUpperCase(),
          filtros: state[tabla].filtros,
          respond: 'true',
        }) // Peticion a BBDD de la longitud de la consulta
        commit('cpedir',[tabla,z,largo[0]['largo'],offset])
      }, //Funciona Ok por el momento
      async filtrar({state,dispatch,commit},[e,tabla]){
        if(e){
          commit('cfiltrar',[e,tabla])
        }
        await dispatch('pedir',[0,null,tabla])
      }, //Funciona Ok
      async ordenar({state,dispatch,commit},[e,tabla]){
        commit('cordenar',[e,tabla])
        await dispatch('pedir',[0,state[tabla].datos.length,tabla])
      }, // Funciona Ok
      async get_campos({state,rootState,commit,dispatch},tabla){
        let camps = await dispatch('api',{
          archivo: 'madre',
          tipo: 'select',
          campos: ['NOMBRE','TITULO','ANCHO','TIPO','FIJADO','LINK'],
          tabla: "PREFERENCES",
          filtros: {
            USUARIO: " ="+rootState.cache.user,
            INDICE: " >=0",
            VISIBLE: " =1",
            TABLA: " ='"+tabla.toUpperCase()+"'"
          },
          limite: 100,
          orden: "INDICE ASC",
          respond: 'true'
        })
        commit('cget_campos',[tabla,camps])
      }, //Funcoina Ok
      async cambiospreferencias({state,rootState,dispatch},[e,tabla]){
        await dispatch('api',{
          archivo: 'madre',
          tipo: 'update',
          campos: {
            ANCHO: " ="+e.ancho
          },
          tabla: "PREFERENCIAS",
          filtros: {
            USUARIO: " ="+rootState.cache.user,
            CAMPO: " = (SELECT ID FROM CAMPOS WHERE TABLA='"+tabla.toUpperCase()+"' AND NOMBRE='"+e.campo+"')",
          },
          respond: 'false'
        })
        await dispatch('get_campos',tabla)
      }, // Funciona Ok
      async ver_mas({state,dispatch},tabla){
        if(state[tabla].datos.length<state[tabla].longitud){
          await dispatch('pedir',[state[tabla].datos.length,null,tabla])
        }
      }, //Funciona Ok
      convertToCSV({state},[objArray,tabla]) {
        const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        let str = "";
        let line = "";
        for (let i = 0; i < state[tabla].campos.length; i++) {
          if (line != "") line += ";";
          line +=  '"'+state[tabla].campos[i].titulo+'"';
        }
        str += line + "\r\n";
        for (let i = 0; i < array.length; i++) {
          let line = "";
          state[tabla].campos.forEach(campo => {
            if (line != "") line += ";";
            if (array[i][campo.nombre]===null){
              line += '""'
            }else if(campo.tipo==='number'){
              line += '"' + array[i][campo.nombre].replace('.',',') + '"';
            }else{
              line += '"' + array[i][campo.nombre] + '"';
            }
          })
          str += line + "\r\n";
        }
        return str;
      },
      async exportCSVFile({state,dispatch},[headers, items,tabla, fileName]) {
        if (headers) {
        items.unshift(headers);
        }
        const jsonObject = JSON.stringify(items);
        const csv = await dispatch('convertToCSV',[jsonObject,tabla])
        const exportName = fileName + ".csv" || "export.csv";
        const blob = new Blob(["\uFEFF"+csv], { type: "text/csv;charset=utf-8" });
        if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportName);
        } else {
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
    },
    getters: { }
}