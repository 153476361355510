import qs from 'qs'
import axios from 'axios'
import router from '@/router/index.js'
export default {
    state: () => ({
        ventanas: [

        ],
        detalles: {
          presupuestos: null,
          empresas: null
        },
        cargas: {
          presupuestos: null,
          empresas: null
        },
        actuales: {
          presupuestos: null,
          empresas: null
        },
        auth: false,
        user: null
    }),
    mutations:{
      cargarindex(state,array){
        const tabla = array[0]
        const index = array[1]
        state.detalles[tabla] = index
      }
    },
    actions: {
      async auth({state}){
        if(localStorage['token']){
          var data = qs.stringify({
            token: localStorage['token'],
            tipo:'auth'
          })
          var config = {
            method: 'post',
            url: 'https://www.centrocatalan.synology.me/php2/back.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
        };
          await axios(config)
          .then(function (response) {
              if(typeof response === 'object'){
                state.auth = response.data.auth
                state.user = response.data.user
              }
          })
          .catch(function (error) {
              console.log(error)
          })
        }
      },
      logout(){
        localStorage.removeItem('token')
        router.go()
      }
    }
  }