<template>
  <div id="app">
    <div id="nav">
      <img src="@/assets/logoblanco.png" alt="logoblanco" style="float:left" width="70px">
      <router-link to="/">Inicio</router-link>
      <router-link to="/presupuestos">Presupuestos</router-link>
      <router-link to="/empresas">Empresas</router-link>
      <router-link to="/contactos">Contactos</router-link>
      <router-link to="/obras">Obras</router-link>
      <router-link to="/facturas">Facturas</router-link>
      <router-link to="/campo">Campo</router-link>
      <router-link to="/preferencias">Preferencias</router-link>
      <router-link v-if="parseInt($store.state.cache.user) < 5" to="/usuarios">Usuarios</router-link>
      <button @click="logout">Cerrar sesión</button>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'App',
  methods: {
    link(url){
      if(this.$route.path!==url){
        this.$router.push(url)
      }
    },
    ...mapActions(['logout','api'])
  }
}
</script>

<style>
#app {
  flex-grow: 0;
  height: 100vh;
  width: 100%;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 500;
  font-size: 110%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
#nav {
  user-select: none;
  position: sticky;
  top: 0;
  font-size: 100%;
  background-color: rgb(245, 138, 67);
  padding: 1vh 2vw;
}

#nav a {
  margin: 0.7vw;
  text-decoration: none;
  font-weight: bold;
  color: white;
}

#nav a.router-link-exact-active {
  color: rgb(126, 79, 52);
}
</style>
