export default {
    state: () => ({ 
      presupuestos: [],
      obras: [],
      empresas: [],
      facturas_geo: null,
      facturas_son: null,
      contactos: null
    }),
    mutations: {
      cargardetalle(state,objeto){
        state[objeto[0]].push(objeto[1])
      }
    },
    actions: {
      cargardetalle: async function ({commit,dispatch},array) {
        const tabla = array[0]
        const id = array[1]
        const datos = new FormData
        datos.append("tabla",tabla)
        datos.append("id",id)
        const data = await fetch('https://www.centrocatalan.synology.me/php2/detalles.php',{
            method: 'POST',
            body: datos
        })
        const res = await data.json()
        if(res[0]!==undefined && tabla==='presupuestos'){
            await dispatch('cargarreferencias')
            await dispatch('cargarempresas')
            await commit('cargardetalle',[tabla,res[0]])
            await dispatch('buscarindex',[tabla,id])
            await dispatch('cargarcontactos')
        }else if(res[0]!==undefined && tabla==='empresas'){
            await commit('cargardetalle',[tabla,res[0]])
            await dispatch('buscarindex',[tabla,id])
        }
      },
      cambiosdetalle: function ({state},object){
        const campo = object[0]
        const valor = object[1]
        const id = object[2]
        const datos = new FormData
        datos.append('field',campo)
        datos.append('value',valor)
        datos.append('ID',id)
        const data = fetch('https://www.centrocatalan.synology.me/php2/guardarpresu.php',{
          method: 'POST',
          body: datos
        })
      },
      buscarindex({state,commit},array){
        const tabla = array[0]
        const id = array[1]
        for (let index = 0; index < state[tabla].length; index++) {
          if(state[tabla][index].ID===id){
            commit('cargarindex',[tabla,index])
          }
        }
      },
      cargarfacturas: async function ({commit},id){
        const datos = new FormData
        datos.append('subtabla','facturas')
        datos.append('id',id)
        const data = await fetch('https://www.scentrocatalan.synology.me/php2/subtablas.php',{
          method: 'POST',
          body: datos
        })
        const res = await data.text()
        commit('cargarfacturas',res)
      }
    },
    getters: { }
  }