<template>
  <div class="home">
    <img src="@/assets/logo.png" alt="logo corporativo">
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Home',
  methods:{
    ...mapActions(['cargarlistas'])
  },
  async beforeMount(){
    await this.cargarlistas()
  }
}
</script>

<style scoped>
img{
  width: 250px;
}
</style>
