export default {
  state: () => ({
    empresas: null,
    cliente: null,
    arquitecto: null,
    contactos: null,
    con_arquitecto: null,
    con_cliente: null,
    tipo_terreno: [],
    tipo_construccion: [],
    tipo_obra: [],
    tipo_proyecto: [],
    geologo: [],
    principal: [],
    pub_priv: [],
    tipo_arquitecto: [],
    idioma: []
  }),
  mutations: { 
    cargarlistasc(state,e){
      for (const key in e) {
        if (Object.hasOwnProperty.call(e, key)) {
          state[key] = e[key]
        }
      }
    }
  },
  actions: {
      async cargarlistas({state,commit}){
        const datos = new FormData
        datos.append('lista','referencias')
        const data = await fetch('https://www.centrocatalan.synology.me/php2/listas.php',{
          method: 'POST',
          body: datos
        })
        const res = await data.json()
        commit('cargarlistasc',res)
      }
  },
  getters: { }
}